import React from 'react'
import { Line } from "react-chartjs-2";
import '../../styles/Dashboard.css';

const TrendChart = ({ responseData, borderColor }) => {

    const labels = responseData.map(data => data.appointment_visit_date);
    const dataPoints = responseData.map(data => parseInt(data.appointment_count));

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Current",
                fill: false,
                lineTension: 0,
                borderColor: borderColor,
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: borderColor,
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: borderColor,
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 0,
                pointHitRadius: 10,
                data: dataPoints
            },
        ]
    };


    const options = {
        legend: {
            display: false,
            position: "bottom"
        },
        scales: {
            xAxes: [
                {
                    display: false // Hide x-axis
                }
            ],
            yAxes: [
                {
                    display: false, // Hide y-axis
                    ticks: {
                        callback: function (value, index, values) {
                            return value + "K";
                        }
                    }
                }
            ],
        },
        plugins: {
            tooltip: {
                mode: "index",
                intersect: false,
                position: "nearest", // Change this to 'average' or 'nearest'
                callbacks: {
                    label: function (context) {
                        return context.parsed.y + "K";
                    }
                }
            }
        }
    };

    return (
        <Line data={data} options={{ ...options, maintainAspectRatio: false, responsive: true }} height={90}/>
    )
}

export default TrendChart
