import { Select, Input } from 'antd';
import React from 'react';
import '../../styles/Dashboard.css'
import dropdownOptions from '../../assets/json/DropdownOptions.json'

const { Search } = Input

const FilterInput = ({ selectedEntityOption, setSelectedEntityOption, selectedAppointmentTypeOption, setSelectedAppointmentTypeOption, selectedAppointmentStatusOption, setSelectedAppointmentStatusOption }) => {


    return (
        <>
            <div className='row marginTopAdjustSelect'>

                <div className="col-12 col-md-6 col-lg-4 col-xl-4 inputContainer">
                    <label htmlFor="patientType" className='mb-1 labelStylingFilter'>Select patient type</label>
                    <Select
                        id="patientType"
                        className='selectInputBox'
                        placeholder="Select patient type"
                        value={selectedEntityOption}
                        onChange={(e) => setSelectedEntityOption(e)}
                        options={dropdownOptions.entityType}
                    />
                </div>

                <div className="col-12 col-md-6 col-lg-4 col-xl-4 inputContainer">
                    <label htmlFor="appointmentType" className='mb-1 labelStylingFilter'>Select appointment type</label>
                    <Select
                        id="appointmentType"
                        className='selectInputBox'
                        placeholder="Select appointment type"
                        value={selectedAppointmentTypeOption}
                        onChange={(e) => setSelectedAppointmentTypeOption(e)}
                        options={dropdownOptions.appointmentType}
                    />
                </div>

                <div className="col-12 col-md-6 col-lg-4 col-xl-4  inputContainer">
                    <label htmlFor="appointmentStatus" className='mb-1 labelStylingFilter'>Select appointment status</label>
                    <Select
                        id="appointmentStatus"
                        mode="multiple"
                        className='selectInputLastBox'
                        // defaultValue={['china']}
                        placeholder="Appointment status"
                        value={selectedAppointmentStatusOption}
                        onChange={(e) => setSelectedAppointmentStatusOption(e)}
                        optionLabelProp="label"
                        options={dropdownOptions.appointmentStatus}
                    />
                </div>
            </div>

        </>
    );
}

export default FilterInput