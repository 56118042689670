import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { notify } from '../utils/notify'
import { Form, Input, Button, Select, Checkbox, Spin, Modal, Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { validateForm } from '../utils/validateForms'
import { convertToTitleCase } from '../utils/stringUtils'
import { getStates } from '../services/StateService'
import { getInsurances } from '../services/InsuranceService'
import { getAgePoints, getAllData, getSpecialization } from '../services/ClinicianService'
import { getAllGHLCalendars, getAllGHLUsers } from '../services/GHLService'
import { categorizeAppointment } from '../utils/categorizeAppointment'

const { Option } = Select

const ClinicianOnboarding = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [showSelectGHLCalendar, setShowSelectGHLCalendar] = useState(true)
  const [states, setStates] = useState([])
  const [insurances, setInsurances] = useState([])
  const [ghlUsersState, setGhlUsersState] = useState([])
  const [ghlCalendarsState, setGhlCalendarsState] = useState([])
  const [providerName, setProviderName] = useState('')
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialValue, setInitialValue] = useState([{}])
  const [appointmentsMap, setAppointmentsMap] = useState({})
  const [agepoints, setAgePoints] = useState([])
  const [specialization, setSpecialization] = useState([])

  const appointments = [
    'Medication',
    'Therapy',
    'Therapy Family',
    'Therapy Group',
    'Therapy Couple',
    'INMHP',
    'Coach',
    'Coach Peer Group',
    'Coach Group'
  ]

  const setFormsFieldValues = async (data) => {
    setProviderName(
      data.provider_firstname
        ? convertToTitleCase(data.provider_firstname) + ' ' + convertToTitleCase(data.provider_lastname)
        : data.provider_column_heading
    )
    if (data.provider_settings) {
      form.setFieldValue('ghl_user_id', data.provider_settings.ghl_user_id)
      form.setFieldValue('ghl_calender_id', data.provider_settings.ghl_calender_id)
      form.setFieldValue(
        'booking_available_status',
        data.provider_settings.booking_available_status === 'both' || data.provider_settings.booking_available_status === 'new' ? true : false
      )
      form.setFieldValue('doxy_link', data.provider_settings.doxy_link)
      form.setFieldValue('timezone', data.provider_settings.timezone)
      form.setFieldValue('specialization', data.provider_settings?.specialization)
      form.setFieldValue('agepoints', data.provider_settings?.agepoints)
      form.setFieldValue(
        'existing_booking_status',
        data.provider_settings.booking_available_status === 'both' || data.provider_settings.booking_available_status === 'follow-up' ? true : false
      )
    }
    const appointments = await getAllData(1, 1, 'get-appt-types')
    setAppointmentTypes(appointments)

    // get an object mappping appointment id and appointment name. e.g.:- {1 : "med new", 2 : "med f/u", ...}
    const appointmentMap = {}

    appointments.forEach((appointment) => {
      appointmentMap[appointment.id] = appointment.name
    })

    // get an object mapping appointment name with array of id. e.g.:- {"medication" : [1,2], ...}
    const appointmentMapping = {}

    appointments.forEach((appointment) => {
      const { name, id } = appointment
      const label = categorizeAppointment(name)

      if (appointmentMapping[label]) {
        appointmentMapping[label].push(id)
      } else {
        appointmentMapping[label] = [id]
      }
    })
    setAppointmentsMap(appointmentMapping)

    //getting supported appointment type by provider
    const appointmentTypeUnique = new Set()
    if (data.provider_appt_types.length !== 0) {
      const appointmentTypes = []
      data.provider_appt_types.map((element) => {
        appointmentTypes.push(element.appt_type_skey)
        if (element.appt_type_skey) appointmentTypeUnique.add(categorizeAppointment(appointmentMap[element.appt_type_skey]))
      })
      // form.setFieldValue('appointment_types', appointmentTypes);

      form.setFieldValue('appointments', [...appointmentTypeUnique])
    }
    if (data.provider_state_insurance.length) {
      let insuranceState = data.provider_state_insurance.reduce((acc, current) => {
        const existingState = acc.find((item) => item.states === current.state_skey)

        if (existingState) {
          existingState.insurances.push(current.insurance_list_skey)
        } else {
          acc.push({
            states: current.state_skey,
            insurances: [current.insurance_list_skey]
          })
        }

        return acc
      }, [])
      form.setFieldValue('insuranceState', insuranceState)
    }
  }

  const timeZone = ['US/Eastern', 'America/Detroit', 'America/New_York', 'US/Central', 'US/Alaska', 'America/Los_Angeles']

  const getAppointmentType = (appointments) => {
    const appointmentTypeArray = []
    if (appointments.length) {
      appointments.forEach((appointment) => {
        appointmentTypeArray.push(...appointmentsMap[appointment])
      })
    }
    return appointmentTypeArray
  }

  const onFinish = async (data) => {
    setLoading(true)
    const errors = validateForm(data)
    if (Object.keys(errors).length === 0) {
      const user = JSON.parse(localStorage.getItem('user'))

      data['current_user'] = user.name
      data['appointment_types'] = data.appointments ? getAppointmentType(data.appointments) : null
      data['booking_available_status'] =
        data.booking_available_status === true && data.existing_booking_status === true
          ? 'both'
          : data.booking_available_status === true
            ? 'new'
            : data.existing_booking_status === true
              ? 'follow-up'
              : 'none'
      data['is_ghl_calendar'] = showSelectGHLCalendar

      const ghlUser = ghlUsersState.find((item) => item.id === data.ghl_user_id)

      try {
        const provider = await getAllData(1, 1, 'update-provider-setting', { ...data, provider_skey: id, provider_name: ghlUser.name })
        notify(`"${providerName}" Clinician Updated Successfully!`)
        setLoading(false)
        navigate('/clinicians')
      } catch (error) {
        console.log(error)
        Modal.confirm({
          title: 'Error',
          content: (
            <div>
              <p>Oops! Failed to update clinician details.</p>
              <p>{error.message}</p>
            </div>
          ),
          okText: 'Ok',
          cancelButtonProps: { style: { display: 'none' } }
        })
      }
      setLoading(false)
    } else {
      setLoading(false)
      console.log(errors)
      const errorMessages = Object.values(errors).map((error, index) => <div key={index}>* {error}</div>)
      Modal.confirm({
        title: 'Error Message',
        content: (
          <div>
            <p>Oops! Cannot update data due to the following errors:</p>
            {errorMessages}
          </div>
        ),
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log('Error in Cannot Update data')
        }
      })
    }
  }

  const goBack = () => {
    navigate('/clinicians')
  }

  const handleGHLCalendar = (e) => {
    setShowSelectGHLCalendar(e.target.checked)
  }

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 }
  }

  const loadClinicianData = async () => {
    try {
      setLoading(true)
      setLoading(true)
      const state = await getStates()
      setStates(state)

      const ghlUsersData = await getAllGHLUsers()
      setGhlUsersState(ghlUsersData.users)

      const ghlCalendarsData = await getAllGHLCalendars()
      setGhlCalendarsState(ghlCalendarsData.calendars)

      const insurance = await getInsurances()
      setInsurances(insurance)

      const specialization = await getSpecialization()
      setSpecialization(specialization.specializations)

      const agepoints = await getAgePoints()
      setAgePoints(agepoints.agepoints)

      await fetchData()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching Clinician Data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log('Error in Fetching data')
        }
      })
      console.error('Error fetching clinician details:', error)
    }
  }

  useEffect(() => {
    loadClinicianData()
  }, [])

  const fetchData = async () => {
    try {
      const provider = await getAllData(1, 1, 'get-one-provider', { id: id })
      setFormsFieldValues(provider)
    } catch (error) {
      Modal.confirm({
        title: 'Error Message',
        content: 'Oops! Error in Fetching Clinician Data',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: async () => {
          console.log('Error in Fetching data')
        }
      })
      console.error('Error fetching clinician details:', error)
    }
  }

  return (
    <div id="dashboard-container" style={{ width: '800px', display: 'flex', flexDirection: 'column' }}>
      <h2 style={{ marginBottom: '20px', marginLeft: 170 }}>Clinician Onboarding</h2>
      <Spin spinning={loading}>
        <div style={{ marginBottom: '20px', marginLeft: 120 }}>
          <strong>Provider Name: </strong>
          {'  '} {providerName}
        </div>
        <Form form={form} onFinish={onFinish} {...formItemLayout} encType="multipart/form-data">
          <Form.Item
            name="ghl_user_id"
            label={'GHL user'}
            rules={[
              {
                message: 'Please select the GHL user.'
              }
            ]}
            style={{ width: 800 }}
          >
            <Select
              placeholder="Select a GHL user"
              style={{ width: 300 }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => {
                const ghlUser = ghlUsersState.find((item) => item.id === value)

                if (!(ghlUser.name.replace(/\s+/g, ' ') === providerName)) {
                  Modal.confirm({
                    title: 'Alert',
                    content: 'AMD provider name and GHL user name not matched. Please verify before moving forward',
                    okText: 'Ok',
                    cancelButtonProps: { style: { display: 'none' } },
                    onOk: async () => {
                      console.log('Error in Fetching data')
                    }
                  })
                }
              }}
            >
              {ghlUsersState.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Is GHL Calender already created?" name="is_ghl_calendar" valuePropName="checked" style={{ width: 800 }}>
            <Checkbox onChange={handleGHLCalendar} defaultChecked></Checkbox>
          </Form.Item>

          {showSelectGHLCalendar && (
            <Form.Item name="ghl_calender_id" label="GHL calendar" style={{ width: 800 }}>
              <Select
                placeholder="Select a GHL calender"
                style={{ width: 300 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  const ghlUser = ghlCalendarsState.find((item) => item.id === value)
                  if (!(ghlUser.name.replace(/\s+/g, ' ') === providerName)) {
                    Modal.confirm({
                      title: 'Alert',
                      content: 'AMD provider name and GHL calender name not matched. Please verify before moving forward',
                      okText: 'Ok',
                      cancelButtonProps: { style: { display: 'none' } },
                      onOk: async () => {
                        console.log('ghl calender')
                      }
                    })
                  }
                }}
              >
                {ghlCalendarsState.map((calendar) => (
                  <Option key={calendar.id} value={calendar.id}>
                    {calendar.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="doxy_link"
            label="Doxy link"
            rules={[
              {
                required: true,
                message: 'Please enter doxy link.'
              }
            ]}
            style={{ width: 800 }}
          >
            <Input placeholder="Enter doxy.me link" style={{ width: 300 }} />
          </Form.Item>

          <Form.Item
            name="timezone"
            label="Time zone"
            rules={[
              {
                required: true,
                message: 'Please enter time zone'
              }
            ]}
            style={{ width: 800 }}
          >
            <Select placeholder="Select a time zone" style={{ width: 300 }}>
              {timeZone.map((time) => (
                <Option key={time} value={time}>
                  {time}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="appointments" label="Select supported appointment type" style={{ width: 800 }}>
            <Select
              placeholder="Select a appointment type(s)"
              mode="multiple"
              style={{ width: 300 }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {appointments.map((appointment) => (
                <Option key={appointment} value={appointment}>
                  {appointment}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="specialization" label="Select supported specialization" style={{ width: 800 }}>
            <Select
              placeholder="Select a specialization(s)"
              mode="multiple"
              style={{ width: 300 }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {specialization?.map((specialization) => (
                <Option key={specialization.id} value={specialization.specialisation}>
                  {specialization.specialisation}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="agepoints" label="Select supported agepoints" style={{ width: 800 }}>
            <Select
              placeholder="Select a agepoint(s)"
              mode="multiple"
              style={{ width: 300 }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {agepoints?.map((agepoint) => (
                <Option key={agepoint.id} value={agepoint.agepoint}>
                  {agepoint.agepoint}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Is available for new booking?" name="booking_available_status" valuePropName="checked" style={{ width: 800 }}>
            <Checkbox />
          </Form.Item>

          <Form.Item label="Is available for follow up booking?" name="existing_booking_status" valuePropName="checked" style={{ width: 800 }}>
            <Checkbox />
          </Form.Item>

          <Form.List name="insuranceState" initialValue={initialValue}>
            {(fields, { add, remove }) => (
              <div style={{ paddingLeft: 55 }}>
                <div>
                  <span style={{ color: 'red' }}>* </span>Select state and insurance:
                </div>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'states']}
                      fieldKey={[fieldKey, 'states']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select state.'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select state"
                        style={{ width: 200 }}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {states.map((state) => (
                          <Option key={state.id} value={state.id}>
                            {state.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'insurances']}
                      fieldKey={[fieldKey, 'insurances']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select insurances.'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select a supported insurance"
                        mode="multiple"
                        style={{ width: 300 }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {insurances.map((insurance) => (
                          <Option key={insurance.id} value={insurance.id}>
                            {insurance.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {fields.length > -1 && (
                      <Button
                        type="dashed"
                        onClick={() => {
                          add()
                        }}
                      >
                        Add State
                      </Button>
                    )}
                    {fields.length > 1 && (
                      <Button
                        type="dashed"
                        onClick={() => {
                          remove(name)
                        }}
                      >
                        Remove State
                      </Button>
                    )}
                    <Tooltip
                      title={
                        <span>
                          To add more state and insurance, click on "Add State"
                          <br />
                          To remove state and insurance, click on "Remove State"
                        </span>
                      }
                      placement="right"
                    >
                      <InfoCircleOutlined style={{ marginLeft: '10px', fontSize: '18px' }} />
                    </Tooltip>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>

          {/* Back and Submit Buttons */}
          <div style={{ display: 'flex', marginLeft: 170 }}>
            <Form.Item style={{ marginRight: 16 }}>
              <Button onClick={goBack}>Back</Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default ClinicianOnboarding
